import React, { lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ROUTES from '../../constants/ROUTES';
import OrderSuccessFullyPlaced from '../../pages/etc-pages/OrderSuccessFullyPlaced';
import { TOKEN_NAME } from '../../_apis/setupAxios';
import { outletMSTActions } from '../../_redux/files/outletMSTRedux';

const Home = lazy(() => import("../../pages/home/Home"))
const Menu = lazy(() => import("../../pages/menu/Menu"))
const UserAccount = lazy(() => import('../../pages/userAccount/UserAccount'))
const CheckOut = lazy(() => import('../../pages/checkout/CheckOut'))
const Offers = lazy(() => import('../../pages/offer/Offers'))
const ContactUs = lazy(() => import('../../pages/contactus/ContactUs'))
const Feedback = lazy(() => import('../../pages/feedback/Feedback'))
const Payment = lazy(() => import('../../pages/payment/Payment'))
const OrderType=lazy(()=>import("../../pages/orderType/OrderTypePage"))
const TAC=lazy(()=>import("../../pages/Policy/TAC"))
const PrivacyPloicy=lazy(()=>import("../../pages/Policy/PrivacyPolicy"))
const   PrivateRoutes = () => {
    const auth = useSelector(s => s.auth.authUser)

    const selectedOutlet = useSelector((state) => state.outletMST.currentOutlet);
    let storedOutlet =      localStorage.getItem("selected-outlet")

    const disptach = useDispatch()

    // const selectedOutlet = true;

    // if (!selectedOutlet?.data?.length) {
    //     console.log("price less")
    //     return <Redirect to="/outlet-selection" />;
    // }

    if (selectedOutlet?.data?.length) {
        
    } else {
        if(!storedOutlet) {  
        
        return <Redirect to="/outlet-selection" />;
    } else {
        
        // disptach(outletMSTActions.setCurrentOutlet(storedOutlet));

    }

}


    return (
        <Switch>
            <Route exact path="/auth" component={() => <pre className="bg-dark text-success p-3"><code style={{ whiteSpace: 'break-spaces' }}>{localStorage.getItem(TOKEN_NAME)}<hr />{JSON.stringify(auth, null, 2)}</code></pre>} />

            {/* All privates routes will be here */}

            <Route exact path={ROUTES.home._path} component={Home} />
            <Route exact path={ROUTES.menu._pathWithParams} component={Menu} />
            <Route exact path={"/chk"} component={CheckOut} />
            <Route path={ROUTES.userAccount._path} component={UserAccount} />
            <Route exact path={ROUTES.offers._path} component={Offers} />
            <Route exact path={ROUTES.about._path} component={ContactUs} />
            <Route exact path={ROUTES.feedback._path} component={Feedback} />
            <Route exact path={ROUTES.orderType._path} component={OrderType} />
            {/* <Route exact path={ROUTES.payment._path} component={Payment} /> */}
            <Route exact path={ROUTES.orderPlaced._path} component={OrderSuccessFullyPlaced} />
            <Route exact path={ROUTES.tac._path} component={TAC} />
            <Route exact path={ROUTES.privacyPolicy._path} component={PrivacyPloicy} />
        </Switch>
    );
};

export default PrivateRoutes;